function Skills() {
    return (
        <section className="resume-section" id="skills">
            <div className="resume-section-content">
                <h2 className="mb-5">我的技能們<br />Skills</h2>
                <div className="subheading mb-0">Programming Languages</div>
                <ul className="list-inline dev-icons">
                    {/* html5 */}
                    <li className="list-inline-item"><i className="fab fa-html5"></i></li>      {/* html5 */}
                    <li className="list-inline-item"><i className="fab fa-css3-alt"></i></li>   {/* css3 */}
                    <li className="list-inline-item"><i className="fab fa-js-square"></i></li>  {/* js */}
                    <li className="list-inline-item"><i className="fab fa-php"></i></li>        {/* php */}
                    <li className="list-inline-item"><i className="bi bi-filetype-sql"></i></li>    {/* sql */}
                </ul>
                <div className="subheading mb-0">Frameworks</div>
                <ul className="list-inline dev-icons">
                    <li className="list-inline-item"><i className="fab fa-bootstrap"></i></li>  {/* bootstrap */}
                    <li className="list-inline-item"><i className="fa-brands fa-laravel"></i></li>  {/* laravel */}
                </ul>
                <div className="subheading mb-0">Tools</div>
                <ul className="list-inline dev-icons">
                    <li className="list-inline-item"><i className="fab fa-ubuntu"></i></li> {/* ubuntu */}
                    <li className="list-inline-item"><i className="fa-brands fa-github"></i></li>   {/* github */}
                    <li className="list-inline-item" style={{width: 46.5+'px'}}><img className="pb-2" src="https://web.postman.co/_ar-assets/images/favicon-1-48.png" alt="" /></li>   {/* postman */}
                </ul>
                {/* <div className="subheading mb-3">Workflow</div>
                <ul className="fa-ul mb-0">
                    <li>
                        <span className="fa-li"><i className="fas fa-check"></i></span>
                        Mobile-First, Responsive Design
                    </li>
                    <li>
                        <span className="fa-li"><i className="fas fa-check"></i></span>
                        Cross Browser Testing & Debugging
                    </li>
                    <li>
                        <span className="fa-li"><i className="fas fa-check"></i></span>
                        Cross Functional Teams
                    </li>
                    <li>
                        <span className="fa-li"><i className="fas fa-check"></i></span>
                        Agile Development & Scrum
                    </li>
                </ul> */}
            </div>
        </section>
    );
}

export default Skills;