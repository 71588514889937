function Education() {
    return (
        <section className="resume-section" id="education">
            <div className="resume-section-content">
                <h2 className="mb-5">讀書的地方<br />Education</h2>
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0">
                            國立臺灣師範大學 N T N U
                        </h3>
                        <div className="subheading mb-3">
                            科技應用與人力資源發展學系 T A H R D
                        </div>
                        <div>學習與科技組</div>
                        <p>AVG GPA: 3.72</p>
                    </div>
                    <div className="flex-shrink-0"><span className="text-primary">September 2019 - June 2023</span></div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div className="flex-grow-1">
                        <h3 className="mb-0">台中市私立衛道高級中學 V T S H</h3>
                        <div className="subheading mb-3">普通科</div>
                    </div>
                    <div className="flex-shrink-0"><span className="text-primary">September 2016 - June 2019</span></div>
                </div>
            </div>
        </section>
    );
}

export default Education;