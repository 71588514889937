function Experience() {
    return (
        <section className="resume-section" id="experience">
            <div className="resume-section-content">
                <h2 className="mb-5">我的專案們<br />Experience</h2>
                {/* myatomyblog */}
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0"><a href="https://myatomyblog.com/" target="_blank">小人物阿賢</a></h3>
                        <div className="subheading mb-3">客戶個人網站製作</div>
                        <p>
                            此專案協助客戶建立包含了內容管理系統的RWD個人網站，內容管理系統以PHP Laravel框架搭配MySQL製作。
                            <br />
                            <a className="btn btn-primary m-1 mt-3" href="https://myatomyblog.com/" target="_blank">小人物阿賢</a>
                        </p>
                        <div className="tags">
                            <span className="hashtag">#PHP</span>
                            <span className="hashtag">#Laravel</span>
                            <span className="hashtag">#MySQL</span>
                            <span className="hashtag">#Bootstrap RWD</span>
                        </div>
                    </div>
                    <div className="flex-shrink-0"><span className="text-primary">2023/05 - Now</span></div>
                </div>
                {/* NTNU RCCCR */}
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0"><a href="https://rcccr.ntnu.edu.tw/" target="_blank">NTNU RCCCR</a></h3>
                        <div className="subheading mb-3">國立臺灣師範大學 文物保存維護研究發展中心 官方網站製作</div>
                        <p>
                            此專案為協助國立台灣師範大學美術系之文物保存維護研究發展中心設計RWD的新網站，以及伺服器設定等。網站除靜態內容外，亦設置了後端的內容管理系統，方便文保中心的人員自行登入新增最新消息至網站中。除此之外，也針對國外瀏覽者加入了英文版的網站內容。
                            <br />
                            <a className="btn btn-primary m-1 mt-3" href="https://rcccr.ntnu.edu.tw/" target="_blank">NTNU RCCCR</a>
                        </p>
                        <div className="tags">
                            <span className="hashtag">#PHP</span>
                            <span className="hashtag">#MySQL</span>
                            <span className="hashtag">#Apache</span>
                            <span className="hashtag">#Bootstrap RWD</span>
                            <span className="hashtag">#i18n</span>
                        </div>
                    </div>
                    <div className="flex-shrink-0"><span className="text-primary">2022/12 - Now</span></div>
                </div>
                {/* TECH PUNK */}
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0"><a href="https://ntnutechpunk112.hunterhsu.net/" target="_blank">TECH PUNK</a></h3>
                        <div className="subheading mb-3">2022台師大科技系 112級畢業專展 網站製作</div>
                        <p>
                            在科技系112級畢業專題成果展的展覽籌備小組中，我作為文件檔案組組長，負責帶領旗下的網站組成員處理專展網站、線上投票以及線上展、展場虛擬實境互動等功能的製作。<br />
                            其中<b>專展網站</b>及<b>線上投票</b>、<b>抽獎</b>、<b>留言</b>等功能由我負責處理，除此之外，網站所需使用的<b>伺服器環境</b>、<b>資料庫</b>及<b>網域</b>也都由我申請、設定與管理。<br />
                            伺服器原本使用自行架設於校內的伺服器，但由於學校網路問題，而改採用<b>Microsoft Azure</b>公有雲平台的虛擬機器服務架設。<br />
                            <a className="btn btn-primary m-1 mt-3" href="https://ntnutechpunk112.hunterhsu.net/" target="_blank">TECH PUNK</a>
                            <a className="btn btn-primary m-1 mt-3" href="https://github.com/ntnuTahrdExhibit112/Exhibit-Site" target="_blank">GitHub Repository</a>
                        </p>
                        <div className="tags">
                            <span className="hashtag">#Azure</span>
                            <span className="hashtag">#PHP</span>
                            <span className="hashtag">#MySQL</span>
                            <span className="hashtag">#Apache</span>
                            <span className="hashtag">#Bootstrap RWD</span>
                            <span className="hashtag">#select2</span>
                            <span className="hashtag">#Chart.js</span>
                        </div>
                    </div>
                    <div className="flex-shrink-0"><span className="text-primary">2022/03 - 2022/11</span></div>
                </div>
                {/* eli5fortahrd */}
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0"><a href="https://eli5fortahrd.hunterhsu.net/" target="_blank">科技系懶人包</a></h3>
                        <div className="subheading mb-3">系上課程資訊、實用內容提供與課程評分網站</div>
                        <p>
                            身為科技系的一員，我們對系上的某些課程內容或資源有興趣或問題時，原本只能透過詢問學長姐或者系上辦公室來取得答案，在經過幾個同學的討論之後，我們決定自行建置屬於我們系的懶人包，內容包含了系上的課程及在我們系裡常見的資工系雙主修課程，以方便系上的所有同學透過這個網站來快速地得到答案。<br />
                            網站的學習內容以類似Crowd Source的形式來進行募集，同學可以自行提供整理好的學習內容，上傳至我們提供的上傳區，再由我們來執行該學習內容的網頁建置。<br />
                            除此之外，系上同學皆可以註冊帳號，並針對課程去進行評論與評分。<br />
                            <a className="btn btn-primary m-1 mt-3" href="https://eli5fortahrd.hunterhsu.net/" target="_blank">科技系懶人包</a>
                            <a className="btn btn-primary m-1 mt-3" href="https://github.com/80113hunterhsu/eli5fortahrd/" target="_blank">GitHub Repository</a>
                        </p>
                        <div className="tags">
                            <span className="hashtag">#PHP</span>
                            <span className="hashtag">#MySQL</span>
                            <span className="hashtag">#Apache</span>
                            <span className="hashtag">#Bootstrap RWD</span>
                        </div>
                    </div>
                    <div className="flex-shrink-0"><span className="text-primary">2021/04 - 2022/06</span></div>
                </div>
                {/* Variable Tester */}
                <div className="d-flex flex-column flex-md-row justify-content-between mb-5">
                    <div className="flex-grow-1">
                        <h3 className="mb-0 no_uppercase"><a href="https://80113hunterhsu.github.io/VariableTester/" target="_blank">Variable Tester</a></h3>
                        <div className="subheading mb-3">英語系研究用軟體</div>
                        <p>
                            透過系上教授的牽線介紹，協助英語系的教授與研究生改良開發研究所需使用的測試用軟體。<br />
                            由於原使用的Anion Variable Tester版本過於老舊，且支援的影片讀取格式(AVI)及系統(僅支援Windows)皆不符合使用需求，因此此次開發使用相容性較高的網頁來製作，整體使用HTML/CSS/JavaScript製作而成，可以讀取mp4格式的影片檔案，並在測試完成後輸出資料標記點作為csv檔案，或是直接輸出圖片檔案。<br />
                            此網站亦有輸出成Windows及MacOS可使用的安裝檔，可於此專案的GitHub Release取得。<br />
                            <a className="btn btn-primary m-1 mt-3" href="https://80113hunterhsu.github.io/VariableTester/" target="_blank">Variable Tester</a>
                            <a className="btn btn-primary m-1 mt-3" href="https://github.com/80113hunterhsu/VariableTester/" target="_blank">GitHub Repository</a>
                        </p>
                        <div className="tags">
                            <span className="hashtag">#chart.js</span>
                            <span className="hashtag">#HTML/CSS/JS</span>
                            <span className="hashtag">#blob</span>
                        </div>
                    </div>
                    <div className="flex-shrink-0"><span className="text-primary">2022/02 - 2022/06</span></div>
                </div>
            </div>
        </section>
    )
}

export default Experience;