function About() {
    return (
        <section className="resume-section" id="about">
            <div className="resume-section-content">
                <h1 className="mb-0 no_uppercase">
                    許恆齊
                    <span className="text-primary ms-3">Hunter Hsu</span>
                </h1>
                <h3 className="mt-3 ms-1">Stay hungry. Stay foolish. </h3>
                <div className="subheading mt-3 mb-3 ms-2">
                    <a href="tel:+886981894539">0981-894-539</a><br />
                    <a href="mailto:80113hunterhsu@gmail.com" target="_blank">80113hunterhsu@gmail.com</a>
                </div>
                <div className="mb-5 ms-1">
                    <p className="lead"><b>{'>'}_</b>&nbsp; PChome 24h 後端工程師</p>
                </div>
                <div className="social-icons">
                    <a className="social-icon" href="https://www.linkedin.com/in/hunterhsu/" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                    <a className="social-icon" href="https://github.com/80113hunterhsu/" target="_blank"><i className="fab fa-github"></i></a>
                    <a className="social-icon" href="https://www.instagram.com/hunter.0913/" target="_blank"><i className="fab fa-instagram"></i></a>
                </div>
            </div>
        </section>
    )
}

export default About;