import About from './PageContents/About';
import Experience from './PageContents/Experience';
import Education from './PageContents/Education';
import Skills from './PageContents/Skills';

function PageContent() {
    return (
        <>
            <About />
            <hr className='m-0' />
            <Experience />
            <hr className='m-0' />
            <Education />
            <hr className='m-0' />
            <Skills />
        </>
    )
}

export default PageContent;