function Nav() {
    let links = [
        { name: {tw: '關於我', en: 'About'}, href: '#about' },
        { name: {tw: '我的專案們', en: 'Experience'}, href: '#experience' },
        { name: {tw: '讀書的地方', en: 'Education'}, href: '#education' },
        { name: {tw: '我的技能們', en: 'Skills'}, href: '#skills' },
        // { name: {tw: '沒事時做的事', en: 'Interests'}, href: '#interests' },
        // { name: {tw: '一些小工具', en: 'Utilities'}, href: '#utilities' }
    ];
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
            <a className="navbar-brand js-scroll-trigger" href="#page-top">
                <span className="d-block d-lg-none">許恆齊 Hunter Hsu</span>
                <span className="d-none d-lg-block"><img className="img-fluid img-profile rounded-circle mx-auto mb-2" src="assets/img/profile.jpg" alt="..." /></span>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav">
                    {
                        links.map((link, index) => {
                            return (
                                <li className="nav-item" key={index}><a className="nav-link js-scroll-trigger" href={link.href}>{link.name.tw}<br />{link.name.en}</a></li>
                            )
                        })
                    }
                </ul>
            </div>
        </nav>
    )
}

export default Nav;